import React from 'react';
import PropTypes from 'prop-types';
import LogoGrid from '../../../components/LogoGrid';

import BeamLogo from '../../../assets/charity-svgs/logo-beam.svg';
import CodeDojoLogo from '../../../assets/charity-svgs/logo-coder-dojo.svg';
import AcsLogo from '../../../assets/charity-svgs/logo-acs.svg';
import BgcLogo from '../../../assets/charity-svgs/logo-bgc.svg';
import BasLogo from '../../../assets/charity-svgs/logo-bas.svg';
import ChlLogo from '../../../assets/charity-svgs/logo-chl.svg';
import AonLogo from '../../../assets/charity-svgs/logo-aon.svg';
import AurLogo from '../../../assets/charity-svgs/logo-aur.svg';
import OprLogo from '../../../assets/charity-svgs/logo-opr.svg';
import ParLogo from '../../../assets/charity-svgs/logo-par.svg';
import CroLogo from '../../../assets/charity-svgs/logo-cro.svg';
import WckLogo from '../../../assets/charity-svgs/logo-wck.svg';
import StrLogo from '../../../assets/charity-svgs/logo-str.svg';
import s from './Charity.module.scss';

const CHARITY_LOGOS = [
  { key: 'beam', logo: <BeamLogo /> },
  { key: 'coderdojo', logo: <CodeDojoLogo /> },
  { key: 'acs', logo: <AcsLogo /> },
  { key: 'bgc', logo: <BgcLogo /> },
  { key: 'bas', logo: <BasLogo /> },
  { key: 'chl', logo: <ChlLogo /> },
  { key: 'aon', logo: <AonLogo /> },
  { key: 'aur', logo: <AurLogo /> },
  { key: 'opr', logo: <OprLogo /> },
  { key: 'par', logo: <ParLogo /> },
  { key: 'cro', logo: <CroLogo /> },
  { key: 'wck', logo: <WckLogo /> },
  { key: 'str', logo: <StrLogo /> },
];

const propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

const Charity = ({ title, description }) => (
  <div className={s.root} data-aos="fade-up" data-aos-delay="600">
    <div className={s.container}>
      <div className={s.innerContainer}>
        <div className={s.section}>
          <h3>{title}</h3>
          <p className={s.paragraph}>{description}</p>
        </div>
        <LogoGrid className={s.logoGrid} logos={CHARITY_LOGOS} />
      </div>
    </div>
  </div>
);

Charity.propTypes = propTypes;
export default Charity;
