import React from 'react';
import PropTypes from 'prop-types';
import renderContent from '../../utils/rich-text-helpers';
import s from './PageDetails.module.scss';

const propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.shape({
    json: PropTypes.shape({
      content: PropTypes.array,
    }),
  }).isRequired,
  description2: PropTypes.shape({
    json: PropTypes.shape({
      content: PropTypes.array,
    }),
  }),
  isDescInTwoColum: PropTypes.bool,
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
  description2: null,
  isDescInTwoColum: false,
};

const PageDetails = ({ title, className, description, description2, isDescInTwoColum }) => (
  <div className={s.containerBg} data-aos="fade-up" data-aos-delay="400">
    <div className={s.container}>
      <div className={[s.root, s.two_part, className].join(' ')}>
        <h3 className={s.title} data-test="pageDetailsTitle">
          {title}
        </h3>
        {description2 || isDescInTwoColum ? (
          <div data-test="pageDetailsDescription" className={s.flaxOuter}>
            <div className={s.flax50}>
              {description?.json?.content.map((content) => renderContent(content))}
            </div>
            <div className={s.flax50}>
              {description2?.json?.content.map((content) => renderContent(content))}
            </div>
          </div>
        ) : (
          <div data-test="pageDetailsDescription" className={s.flaxOuter_paragraph}>
            {description?.json?.content.map((content) => renderContent(content, s.paragraph))}
          </div>
        )}
      </div>
    </div>
  </div>
);

PageDetails.defaultProps = defaultProps;
PageDetails.propTypes = propTypes;
export default PageDetails;
