import React from 'react';
import WolrdMapDesktop from './images/map-desktop.svg';
import WolrdMap from './images/map.svg';
import MapPin from './images/map-pin.svg';
import s from './GlobalPresence.module.scss';

const GlobalPresence = () => (
  <div className={s.container}>
    <div className={s.root}>
      <h2 data-aos="fade-up" data-aos-delay="500">
        Our global presence
      </h2>
      <div className={s.innerContainer} data-aos="fade-up" data-aos-delay="300">
        <div className={s.location} data-aos="fade-up" data-aos-delay="500">
          <p className={s.addressTitle}>Los Angeles, CA</p>
          <p className={s.address}>
            Headquarter
            <br />
            1150 South Olive St Floor 10, Los Angeles, CA, 90015
          </p>
        </div>

        <div className={s.mapImage} data-aos="fade-up" data-aos-delay="300">
          <WolrdMapDesktop className={s.mapDesktop} />
          <div className={s.mapMobile}>
            <div className={s.mapLA}>
              <span className={s.pin}>
                <MapPin />
              </span>
              <span className={s.locationName}>Los Angeles</span>
            </div>
            <div className={s.mapGU}>
              <span className={s.pin}>
                <MapPin />
              </span>
              <span className={s.locationName}>Guadalajara</span>
            </div>
            <div className={s.mapFL}>
              <span className={s.pin}>
                <MapPin />
              </span>
              <span className={s.locationName}>Florida</span>
            </div>
            <div className={s.mapAhmedabad}>
              <span className={s.pin}>
                <MapPin />
              </span>
              <span className={s.locationName}>Ahmedabad</span>
            </div>
            <div className={s.mapPune}>
              <span className={s.pin}>
                <MapPin />
              </span>
              <span className={s.locationName}>Pune</span>
            </div>
            <WolrdMap />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default GlobalPresence;
