import React from 'react';
import PropTypes from 'prop-types';
import s from './LogoGrid.module.scss';

const propTypes = {
  logos: PropTypes.arrayOf(
    PropTypes.shape({
      logo: PropTypes.node.isRequired,
      key: PropTypes.string.isRequired,
    }),
  ).isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

const LogoGrid = ({ logos, className }) => (
  <div className={[s.root, className].join(' ')}>
    {logos.map((logo) => (
      <div key={logo.key}>{logo.logo}</div>
    ))}
  </div>
);

LogoGrid.propTypes = propTypes;
LogoGrid.defaultProps = defaultProps;
export default LogoGrid;
