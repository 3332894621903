import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../../components/Layout';
import PageHeader from '../../components/PageHeader';
import CompanyQuote from '../../components/CompanyQuote';
import PageDetails from '../../components/PageDetails';
import GlobalPresence from '../../components/GlobalPresence';
import { getSeoConfig } from '../../constants/defaultSeoProps';
import Charity from './Charity';

const propTypes = {
  companyQuote: PropTypes.shape({
    title: PropTypes.string,
    quote: PropTypes.string,
    footerDescription: PropTypes.shape({}).isRequired,
    icons: PropTypes.array.isRequired,
  }).isRequired,
  detailsTitle: PropTypes.string.isRequired,
  detailsDescription: PropTypes.shape({}).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  charityTitle: PropTypes.string.isRequired,
  charityDescription: PropTypes.string.isRequired,
};

const seoParams = {
  ogImagePath: '/og-images/about.png',
  ogTitle: 'About | Tech Holding',
  ogDesc:
    'Discover the story behind TechHolding, our mission, and our commitment to innovation. Learn about our team of experts driving technological advancement and digital transformation.',
  ogPath: '/about',
};

const updatedSeo = getSeoConfig(seoParams);

const About = ({
  detailsTitle,
  detailsDescription,
  companyQuote,
  title,
  description,
  charityTitle,
  charityDescription,
}) => (
  <Layout
    currentPage="/about"
    seo={updatedSeo}
    title={updatedSeo.openGraph.title}
    footerDescription={companyQuote.footerDescription}
  >
    <PageHeader title={title} description={description} />
    <PageDetails title={detailsTitle} description={detailsDescription} />
    <GlobalPresence />
    <Charity title={charityTitle} description={charityDescription} />
    <CompanyQuote companyQuote={companyQuote} />
  </Layout>
);

About.propTypes = propTypes;
export default About;
