import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import About from '../containers/About';

const propTypes = {
  data: PropTypes.shape({
    contentfulCompanyQuote: PropTypes.shape({
      title: PropTypes.string,
      quote: PropTypes.string,
      footerDescription: PropTypes.shape({}).isRequired,
      icons: PropTypes.arrayOf({
        fluid: PropTypes.shape({}),
      }).isRequired,
    }),
    contentfulAboutPage: PropTypes.shape({
      detailsDescription: PropTypes.shape({}).isRequired,
      detailsTitle: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      charityTitle: PropTypes.string.isRequired,
      charityDescription: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

const AboutPage = ({ data }) => (
  <About
    title={data.contentfulAboutPage.title}
    description={data.contentfulAboutPage.description}
    detailsTitle={data.contentfulAboutPage.detailsTitle}
    detailsDescription={data.contentfulAboutPage.detailsDescription}
    companyQuote={data.contentfulCompanyQuote}
    charityTitle={data.contentfulAboutPage.charityTitle}
    charityDescription={data.contentfulAboutPage.charityDescription}
  />
);

AboutPage.propTypes = propTypes;
export default AboutPage;

export const query = graphql`
  query {
    contentfulAboutPage {
      title
      description
      detailsTitle
      detailsDescription {
        json
      }
      charityTitle
      charityDescription
    }
    contentfulCompanyQuote(label: { eq: "servicesAws" }) {
      title
      quote
      footerDescription {
        json
      }
      icons {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`;
